import React, { useEffect, useCallback } from "react";
import "./map-select-brach.scss";

const MapSelectBranch = ({ branches = [], selectedBranches, onClick }) => {
	let root = null;
	let id = 0;

	let branchesWithIndex = branches.map(branch => {
		branch.id = ++id;
		return branch;
	});

	useEffect(() => {
		if (selectedBranches && selectedBranches.length) {
			const firstSelectedElement = branches.find(
				branch => branch.path === selectedBranches[0]
			);
			let element =
				firstSelectedElement &&
				root.querySelector(`[id='${firstSelectedElement.id}']`);
			if (root && element) {
				root.scrollTop = element.offsetTop;
			}
		}
	}, [branches, root, selectedBranches]);

	const selectBranch = useCallback(
		branch => () => {
			let cloneSelectedBranches = [...selectedBranches];
			if (selectedBranches.indexOf(branch.path) === -1) {
				cloneSelectedBranches.push(branch.path);
			} else {
				const number = selectedBranches.indexOf(branch.path);
				cloneSelectedBranches.splice(number, 1);
			}
			onClick(cloneSelectedBranches);
		},
		[selectedBranches, onClick]
	);

	return (
		<div className="map-select-branch">
			<div className="grid-container">
				<div className="row">
					<ul
						className="map-select-branch__list"
						ref={c => {
							root = c;
						}}
					>
						{branchesWithIndex &&
							branchesWithIndex.map((branch, index) => {
								const { title, path, image, id } = branch;

								return (
									<li
										className={`${
											selectedBranches.indexOf(path) !== -1 ? "active" : ""
										} col-xs-12`}
										key={index}
										id={id}
										onClick={selectBranch(branch)}
									>
										<img alt={title} src={image} />
										{title}
									</li>
								);
							})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default MapSelectBranch;
