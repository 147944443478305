import React, { useState } from "react";
import "./map-select-type-branch.scss";
import Tooltip from "rc-tooltip";

const MapSelectTypeBranch = ({ types, currentType, onClick, className }) => {
	const [opened, setOpened] = useState(false);

	return (
		<div className={`map-select-type-branch ${className}`}>
			<Tooltip
				trigger={["hover"]}
				overlay={
					<div className="map-select-type-branch__tooltip">
						<ul>
							{types &&
								types.map(type => (
									<li
										key={type.id}
										className={`${type.id === currentType.id ? "active" : ""}`}
										onClick={() => {
											onClick(type);
											setOpened(false);
										}}
									>
										<h4>{type.title}</h4>
									</li>
								))}
						</ul>
					</div>
				}
				visible={opened}
				onVisibleChange={() => setOpened(!opened)}
				placement="bottomLeft"
				destroyTooltipOnHide
				overlayClassName="offset"
				mouseEnterDelay={0.3}
				mouseLeaveDelay={0.1}
				align={{
					offset: [-10, 10]
				}}
			>
				<h4>
					{currentType && currentType.title}
					<i className={`arrow ${opened && "opened"}`} />
				</h4>
			</Tooltip>
		</div>
	);
};

export default MapSelectTypeBranch;
