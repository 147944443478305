import React from "react";

import "./outlets-template.scss";
import { Layout } from "@components/layout";
import OutletsOnMap from "@components/outlets-on-map";

const OutletsTemplate = ({ pageContext = {}, ...rest }) => {
	return (
		<Layout {...rest}>
			<section className="full-width outlets">
				<div className="grid-container">
					<div className="row">
						<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
							<h2 className="refill__header">Пункты пополнения ТК</h2>
						</div>
					</div>
				</div>
				<OutletsOnMap pageContext={pageContext} />
			</section>
		</Layout>
	);
};

export default OutletsTemplate;
