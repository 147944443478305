import React from "react";
import "./map-list-type-branch.scss";

const MapListTypeBranch = ({ types, currentType, onClick, className }) => {
	return (
		<div className={`map-list-type-branch ${className}`}>
			<ul>
				{types &&
					types.map(type => (
						<li
							key={type.id}
							className={`${type.id === currentType.id ? "active" : ""}`}
							onClick={() => {
								onClick(type);
							}}
						>
							<h4>{type.title}</h4>
						</li>
					))}
			</ul>
		</div>
	);
};

export default MapListTypeBranch;
