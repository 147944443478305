import React, { useState, useMemo } from "react";
import {
	YMaps,
	Map,
	Placemark,
	GeolocationControl,
	ZoomControl,
	Clusterer
} from "react-yandex-maps";

import "./map-with-select.scss";
import shadow from "@img/shadow.png";

import {
	MapControls,
	MapListTypeBranch
} from "@components/map-with-select/components";

import { transformLocations } from "@utils/utils";

const MapWithSelect = ({
	data,
	defaultType,
	defaultBranches,
	showMapControls = true
}) => {
	/** Конфиг карты */
	const [center, setCenter] = useState([55.796289, 49.108795]);
	const mapState = useMemo(
		() => ({
			center,
			zoom: 11,
			controls: ["fullscreenControl"]
		}),
		[center]
	);

	/** Типы отделений */
	const types = [
		{
			id: 1,
			title: "Где пополнить",
			code: "where-to-refill"
		},
		{
			id: 2,
			title: "Где купить",
			code: "where-to-buy"
		}
	];
	const [currentType, setCurrentType] = useState(
		defaultType
			? types.find(type => type.code === defaultType) || types[0]
			: types && types[0]
	);

	/** Отделения */
	const getCurrentBranches = ({ code }) => {
		return (
			data &&
			data[code] &&
			data[code].edges.map(({ node }) => {
				let res;
				if (node) {
					res = node.frontmatter;
				}
				return res;
			})
		);
	};

	const branches = useMemo(() => getCurrentBranches(currentType), [
		currentType
	]);
	const [selectedBranches, setSelectedBranches] = useState(() => {
		if (defaultType && defaultBranches) {
			return [`/${defaultType}/${defaultBranches}`];
		}
		if (defaultType && (!defaultBranches || !defaultBranches.length)) {
			// Выделяем все точки по умолчанию
			let allBranches = [];
			types.forEach(type => {
				allBranches = [
					...allBranches,
					...(getCurrentBranches(type) && getCurrentBranches(type).length
						? getCurrentBranches(type).map(branch => branch.path)
						: [])
				];
			});
			return allBranches;
		}
		return [];
	});

	/** Локации */
	const [locations, setLocations] = useState([]);
	const getLocations = (branches, selectedBranches) => {
		let locations = [];
		branches &&
			branches.forEach(branch => {
				selectedBranches &&
					selectedBranches.forEach(selectedBranch => {
						if (branch.locations && branch.path === selectedBranch) {
							let arr = branch.locations.map(location => {
								location.image = branch.image;
								return location;
							});
							locations = [...locations, ...arr];
						}
					});
			});
		setLocations(transformLocations(locations));
	};
	useMemo(() => getLocations(branches, selectedBranches), [
		branches,
		selectedBranches
	]);

	return (
		<section className="map-with-select">
			<div className="grid-container hidden-md">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<MapListTypeBranch
							types={types}
							onClick={type => setCurrentType(type)}
							currentType={currentType}
						/>
					</div>
				</div>
			</div>
			<div className="map-with-select__ymaps">
				<YMaps>
					<Map
						className="map-with-select__ymaps__map"
						state={mapState}
						modules={["control.FullscreenControl"]}
						instanceRef={ref => {
							ref && ref.behaviors.disable("scrollZoom");
						}}
					>
						<ZoomControl
							options={{
								position: {
									right: 10,
									top: 108
								}
							}}
						/>
						<GeolocationControl options={{ float: "right" }} />
						<Clusterer
							modules={["clusterer.addon.balloon"]}
							options={{
								preset: "islands#invertedBlueClusterIcons",
								groupByCoordinates: false,
								clusterDisableClickZoom: false,
								clusterHideIconOnBalloonOpen: false,
								geoObjectHideIconOnBalloonOpen: false,
								zoomMargin: [200, 200, 200, 200]
							}}
						>
							{locations &&
								!!locations.length &&
								locations.map(
									({ id, title, workTime, placeMark, phone, image }) => (
										<Placemark
											key={id}
											modules={["geoObject.addon.balloon"]}
											geometry={placeMark}
											onClick={() => {
												setCenter(placeMark);
											}}
											properties={{
												balloonContentHeader: title,
												balloonContentBody: phone && phone.join(",<br/>"),
												balloonContentFooter:
													workTime && workTime.join(",<br/>"),
												clusterCaption: title
											}}
											options={{
												iconLayout: "default#image",
												iconImageHref: image,
												iconImageSize: [32, 32],
												iconImageOffset: [-16, -8],
												iconShadow: true,
												iconShadowImageHref: shadow,
												iconShadowImageSize: [42, 42],
												iconShadowImageOffset: [-16, -8],
												hideIconOnBalloonOpen: false
											}}
										/>
									)
								)}
						</Clusterer>
					</Map>
				</YMaps>
			</div>
			{showMapControls && (
				<MapControls
					content={{
						types,
						currentType,
						branches,
						selectedBranches
					}}
					setCurrentType={type => setCurrentType(type)}
					setSelectedBranches={branches => setSelectedBranches(branches)}
				/>
			)}
		</section>
	);
};

export default MapWithSelect;
