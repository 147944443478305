import React from "react";
import "./map-controls.scss";

import MapSelectTypeBranch from "../map-select-type-branch/map-select-type-branch";
import MapSelectBranch from "../map-select-branch/map-select-branch";

const MapControls = ({ content, setCurrentType, setSelectedBranches }) => {
	const { types, currentType, branches, selectedBranches } = content;

	return (
		<div className="map-controls">
			<MapSelectTypeBranch
				className="hidden-sm hidden-xs"
				types={types}
				onClick={type => setCurrentType(type)}
				currentType={currentType}
			/>
			<MapSelectBranch
				branches={branches}
				selectedBranches={selectedBranches}
				onClick={branch => setSelectedBranches(branch)}
			/>
		</div>
	);
};

MapControls.propTypes = {};

MapControls.defaultProps = {};

export default MapControls;
