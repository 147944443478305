import React from "react";
import PropTypes from "prop-types";
import MapWithSelect from "@components/map-with-select";

import { graphql, StaticQuery } from "gatsby";

const OutletsOnMap = ({ data, pageContext = {}, showMapControls }) => {
	const { defaultType, defaultBranches } = pageContext;
	return (
		<MapWithSelect
			data={{
				"where-to-buy": data.whereToBuy,
				"where-to-refill": data.whereToRefill
			}}
			defaultType={defaultType}
			defaultBranches={defaultBranches}
			showMapControls={showMapControls}
		/>
	);
};

OutletsOnMap.propTypes = {
	pageContext: PropTypes.shape({
		defaultType: PropTypes.oneOf(["where-to-buy", "where-to-refill"])
	})
};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				whereToRefill: allMarkdownRemark(
					filter: { frontmatter: { path: { regex: "/where-to-refill/" } } }
				) {
					edges {
						node {
							id
							frontmatter {
								image
								path
								title
								locations {
									title
									workTime {
										time
									}
									placeMark {
										x
										y
									}
									phone {
										number
									}
									fax {
										number
									}
								}
							}
						}
					}
				}
				whereToBuy: allMarkdownRemark(
					filter: { frontmatter: { path: { regex: "/where-to-buy/" } } }
				) {
					edges {
						node {
							id
							frontmatter {
								image
								path
								title
								locations {
									title
									workTime {
										time
									}
									placeMark {
										x
										y
									}
									phone {
										number
									}
									fax {
										number
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => <OutletsOnMap data={data} {...props} />}
	/>
);
